import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TRANSPORT_FORM_LIFTGATE_INSTRUCTIONS } from '../../../../../../../../i18n/translations/TR';

interface LiftGateTypeFieldProps {
    disabled: boolean
    liftGateRegister: UseFormRegisterReturn;
}

export const LiftGateField = (props: LiftGateTypeFieldProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="col-lg-6 transport-form-container-item">
            <div className="form-check">
                <input
                    type="checkbox"
                    id="liftGateSupplement"
                    disabled={props.disabled}
                    {...props.liftGateRegister}
                    className="form-check-input"
                />
                <label className="form-check-label" htmlFor="liftGateSupplement">
                    <strong>{t(TRANSPORT_FORM_LIFTGATE_INSTRUCTIONS)}</strong>
                </label>
            </div>
        </div>
    );
};
