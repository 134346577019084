import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { DiscountResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/DiscountResponse';
import { Discount } from 'app/orders/model/value-objects/Discount';
import Cart from '../../../../domain/model/Cart';
import { CartId } from '../../../../domain/value-objects/CartId';
import { ApiCartElement } from '../../../../shared/ApiCartItem';
import { Item } from '../../../../shared/ApiItem';
import { ShippingAddressId } from '../../../../../orders/model/ShippingAddressId';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { Weight } from '../../../../../orders/model/value-objects/Weight';
import { CartItemMapper } from './CartItemMapper';
import { CreditMemoMapper } from '../../../../../shared/infrastructure/tencer-api/data-mapper/credit-memo.mapper';
import { TransportMapper } from '../../../../../orders/infrastructure/repository/api/mappers/transport-mapper';
import { UserClientRef } from '../../../../../shared/domain/value-objects/UserClientRef';

export default class CartMapper {

    public static fromResponse(response: ApiCartElement | null): Cart | null {
        if (!response) {
            return null;
        }
        const creditMemo = CreditMemoMapper.map({
            memo_id: response.credit_memo_id,
            amount: response.credit_memo_amount?.amount ?? 0.0,
        });
        return new Cart(
            new CartId(response.id),
            response.client_internal_ref ? new UserClientRef(response.client_internal_ref) : null,
            new ShippingAddressId(response.delivery_address_code),
            new Date(response.free_stock_at),
            response.items.map((item: Item) => CartItemMapper.fromResponse(item)),
            response ? new OrderId(response.order_id) : null,
            new Price(response.price.amount ?? 0.0),
            new Weight(response.weight.amount),
            response.discounts?.map(
                (discount: DiscountResponse) => new Discount(discount.percent, discount.description),
            ),
            creditMemo,
            TransportMapper.fromCartResponse(response.shipping),
        );
    }

}
