import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ACCOUNT,
    ACCOUNT_FIELD_PLACEHOLDER,
    OBLIGATORY_FIELD_ERROR,
} from '../../../../../../../../i18n/translations/TR';

interface AccountFieldProps {
    accountRegister: UseFormRegisterReturn;
    accountFieldError: FieldError | undefined
}

export const AccountField = (props: AccountFieldProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="transport-form-container-item__account">
            <label
                htmlFor="transportAccount"
                className="form-label"
            >
                <span>{t(ACCOUNT)}</span>
            </label>
            <input
                type="text"
                id="transportAccount"
                className="form-control account-input"
                {...props.accountRegister}
                placeholder={t(ACCOUNT_FIELD_PLACEHOLDER)}
            />
            {props.accountFieldError ? (
                <div className="error-input-form">
                    {t(OBLIGATORY_FIELD_ERROR)}
                </div>
            ) : null}
        </div>
    );
};
