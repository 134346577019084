import { PaymentTerms } from '../../../../orders/model/value-objects/PaymentTerms';
import { PaymentTermsResponse } from '../dto/responses/PaymentTermsResponse';
import { Price } from '../../../../orders/model/value-objects/Price';

export const PaymentTermsMapper = {

    map(data: PaymentTermsResponse): PaymentTerms {
        return new PaymentTerms(
            new Price(data.credit_available),
            new Price(data.credit_limit),
            `${this.stripDSuffix(data.limit_days)}`,
        );
    },

    stripDSuffix(value: string): number {
        return Number(value.endsWith('D') ? value.slice(0, -1) : value);
    },

};
