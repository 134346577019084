import React from 'react';
import { UseCartSummaryResponse } from '../../../../hooks/useCartSummary';
import { OrderShippingAddressSelectorModal } from './shippingAddressSelectorModal/OrderShippingAddressSelectorModal';
import { UserCredentialId } from '../../../../../../shared/domain/value-objects/UserCredentialId';

interface IOrderShippingAddressProps {
    UseCartSummary: UseCartSummaryResponse
}
export const OrderShippingAddress = ({
    UseCartSummary,
}: IOrderShippingAddressProps): React.ReactElement => {

    return (
        <div className="order-shipping-address-container">
            {UseCartSummary.UseCartSummaryList.erpUserId && UseCartSummary.tokenPayload?.username ? (
                <OrderShippingAddressSelectorModal
                    erpUserId={UseCartSummary.UseCartSummaryList.erpUserId}
                    countries={UseCartSummary.UseCartSummaryList.countries}
                    defaultShippingAddress={UseCartSummary.shippingAddressSelected}
                    afterChangeShippingAddressDefault={UseCartSummary.afterChangeShippingAddressDefault}
                    userCredentialId={
                        new UserCredentialId(UseCartSummary.tokenPayload.username)
                    }
                />
            ) : null}
        </div>
    );
};
