import { Price } from './Price';

export class PaymentTerms {

    constructor(
        public readonly credit_available: Price,
        public readonly credit_limit: Price,
        public readonly period: string,
    ) {}

    public hasExceedLimit(cartTotalAmount: Price): boolean {
        return this.credit_available.add(cartTotalAmount).amount > this.credit_limit.amount;
    }

}
