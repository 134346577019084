import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, UseFormReturn } from 'react-hook-form';
import { CourierServiceId } from 'app/shared/domain/value-objects/CourierServiceId';
import { CurrentUserContext, UserContextType } from '../../../shared/context/CurrentUserContext';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';
import { useCartSummaryList, UseCartSummaryListResponse } from './useCartSummaryList';
import { useCartTransport, UseCartTransportResponse } from './useCartTransport';
import { useOrigin } from '../../../shared/hooks/useOrigin';
import { useToken, useTokenResponse } from '../../../shared/hooks/use-token';
import { Origin } from '../../../shared/domain/value-objects/Origin';
import Cart from '../../../cart/domain/model/Cart';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import { TransportFormName } from '../../domain/entity/TransportForm';
import { ETransportType, TransportType } from '../../../orders/model/value-objects/transport-type';
import {
    CreditMemoFieldName,
    CreditMemoFormRegisters,
} from '../../../orders/application/components/CreditMemoForm/CreditMemoForm';
import { PaymentMethod } from '../../../orders/model/value-objects/PaymentMethod';
import { ICreditMemo } from '../../../orders/model/CreditMemo';
import Transport from '../../../orders/model/transport';
import { CourierId } from '../../../shared/domain/value-objects/CourierId';
import { DependencyInjectionContext } from '../../../shared/context/DependecyInjectionContext';
import { PaymentTerms } from '../../../orders/model/value-objects/PaymentTerms';
import { ShippingAddressDefault } from '../../../orders/model/ShippingAddressDefault';
import { useCartPO, UseCartPOResponse } from './useCartPO';

export interface UseCartSummaryResponse {
    pageName: string;
    watchLiftGate: boolean;
    exceedTermsLimit: boolean;
    watchConsolidated: boolean;
    origin: Origin | undefined;
    UseCartPO: UseCartPOResponse;
    state: UserContextType | null;
    cart: Cart | null | undefined;
    tokenPayload: useTokenResponse;
    cartSummaryScreenIsBusy: boolean;
    watchResponsible: ETransportType;
    confirmCart: () => Promise<void>;
    showShippingAddressError: boolean;
    creditMemos: Promise<ICreditMemo[]>;
    UseCartTransport: UseCartTransportResponse;
    handleHideShippingAddressError: () => void;
    UseCartSummaryList: UseCartSummaryListResponse;
    creditMemoForm: UseFormReturn<CreditMemoFormRegisters>;
    shippingAddressSelected: ShippingAddressDefault | undefined
    afterChangeShippingAddressDefault: (shippingAddress: ShippingAddressDefault) => void;
}

export const useCartSummary = (): UseCartSummaryResponse => {
    const state = useContext(CurrentUserContext);
    const dic = useContext(DependencyInjectionContext);
    const isMounted = useIsMounted();
    const origin = useOrigin();
    const navigate = useNavigate();
    const tokenPayload = useToken();
    const UseCartTransport = useCartTransport();
    const UseCartSummaryList = useCartSummaryList();
    const UseCartPO = useCartPO(UseCartSummaryList.cart);

    const pageName = 'cart-summary';
    const cart = UseCartSummaryList?.cart;
    const [checkingOut, setCheckingOut] = useState(false);
    const [exceedTermsLimit, setExceedTermsLimit] = useState(false);
    const [showShippingAddressError, setShowShippingAddressError] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms | undefined>();
    const [shippingAddressSelected, setShippingAddressSelected] = useState<ShippingAddressDefault | undefined>();
    const [creditMemos, setCreditMemos] =
        useState<Promise<ICreditMemo[]>>(Promise.resolve([]));

    const cartSummaryScreenIsBusy = UseCartSummaryList.isLoading || checkingOut;

    const creditMemoForm = useForm<CreditMemoFormRegisters>({
        defaultValues: {
            creditMemo: '',
        },
    });

    const watchCourier = UseCartTransport.UseTransportForm.watch(TransportFormName.COURIER);
    const watchLiftGate = UseCartTransport.UseTransportForm.watch(TransportFormName.LIFT_GATE);
    const watchResponsible = UseCartTransport.UseTransportForm.watch(TransportFormName.RESPONSIBLE);
    const watchConsolidated = UseCartTransport.UseTransportForm.watch(TransportFormName.CONSOLIDATED);

    const watchCreditMemo = creditMemoForm.watch(CreditMemoFieldName.CREDIT_MEMO);

    const handleShowShippingAddressError = (): void => {
        setShowShippingAddressError(true);
    };

    const handleHideShippingAddressError = (): void => {
        setShowShippingAddressError(false);
    };

    const checkoutCart = async (): Promise<void> => {
        if (!cart) {
            return Promise.resolve();
        }
        await dic.cartRepository.checkout();
        state?.setExpirationDate(null);
        state?.setCartItems(undefined);
        return Promise.resolve();
    };

    const handleCheckoutForEU = (): Promise<void> => {
        if (!shippingAddressSelected) {
            handleShowShippingAddressError();
            return Promise.resolve();
        }
        return checkoutCart()
            .then(() => navigate(TencerRoutes.CHECKOUT_OK))
            .catch(() => navigate(TencerRoutes.CHECKOUT_KO));
    };

    const validateShippingAddress = (): boolean => {
        if (watchResponsible !== ETransportType.WILL_CALL && !shippingAddressSelected) {
            handleShowShippingAddressError();
            return false;
        }
        return true;
    };

    const handlePaymentNavigation = async (paymentMethod: PaymentMethod): Promise<void> => {
        switch (paymentMethod) {
            case PaymentMethod.CREDIT_CARD:
                navigate(TencerRoutes.CART_PAYMENT_CARD);
                break;
            case PaymentMethod.TERMS:
                await checkoutCart()
                    .then(() => navigate(TencerRoutes.CHECKOUT_OK))
                    .catch(() => navigate(TencerRoutes.CHECKOUT_KO));
                break;
            default:
                navigate(TencerRoutes.CART_PAYMENT_CARD);
                break;

        }
    };

    const confirmCart = async (): Promise<void> => {
        setCheckingOut(true);

        try {
            if (origin !== Origin.USA) {
                await handleCheckoutForEU();
                return Promise.resolve();
            }

            if (!cart || !UseCartPO.validatePO() || !validateShippingAddress()) {
                return Promise.resolve();
            }

            UseCartPO.cartPOForm.clearErrors();

            await UseCartTransport.UseTransportForm.handleSubmit(async (data) => {
                const courier = UseCartTransport.findCourier(new CourierId(data.courier));
                const courierService = UseCartTransport.findCourierService(new CourierServiceId(data.courierService));

                const pickHandlePrice = !watchConsolidated && UseCartSummaryList.erpUser?.pickHandle ?
                    UseCartTransport.pickHandlePrice :
                    undefined;

                cart.transport = new Transport(
                    new TransportType(data.responsible),
                    data.consolidated,
                    data.comments,
                    data.account,
                    courier,
                    courierService,
                    data.liftGate,
                    undefined,
                    pickHandlePrice,
                );

                await creditMemos.then((elements: ICreditMemo[]) => {
                    if (elements.length > 0) {
                        cart.creditMemo = elements.find((creditMemo) => creditMemo.id === watchCreditMemo) ?? undefined;
                    }
                });

                try {
                    await dic.cartRepository.confirm(cart);
                } catch (error) {
                    navigate(TencerRoutes.CART_PAYMENT_KO);
                    return Promise.reject(error);
                }

                const paymentMethod = tokenPayload.paymentMethod;

                await handlePaymentNavigation(paymentMethod as PaymentMethod);

                return Promise.resolve();
            })();
        } finally {
            setCheckingOut(false);
        }

        return Promise.resolve();
    };

    const afterChangeShippingAddressDefault = (shippingAddress: ShippingAddressDefault): void => {
        if (origin === Origin.USA) {
            if (cart?.transport) {
                cart.transport.courier = undefined;
            }
            UseCartTransport.afterChangeShippingAddressDefault();
        }
        setShippingAddressSelected(shippingAddress);
    };

    const updateCartTransportAfterCourierSelection = (): void => {
        if (!cart) {
            return;
        }

        if (!cart?.transport) {
            cart.transport = new Transport(
                new TransportType(watchResponsible),
                watchConsolidated,
                '',
                null,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
            );
        }

        if (watchCourier) {
            cart.transport.courier = UseCartTransport.findCourier(new CourierId(watchCourier));
        }

        if (!watchCourier) {
            cart.transport.courier = undefined;
        }
    };

    const handleTransportPriceOnPickHandleChange = (): void => {
        if (!cart || !UseCartTransport.pickHandlePrice) {
            return;
        }

        if (!cart?.transport) {
            cart.transport = new Transport(
                new TransportType(watchResponsible),
                watchConsolidated,
                '',
                null,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
            );
        }

        if (!watchConsolidated && UseCartSummaryList.erpUser?.pickHandle) {
            cart.transport.pickHandlePrice = UseCartTransport.pickHandlePrice;
        }

        if (watchConsolidated || !UseCartSummaryList.erpUser?.pickHandle) {
            cart.transport.pickHandlePrice = undefined;
        }
    };

    useEffect(() => {
        if (!paymentTerms) {
            dic.erpUserRepository.getPaymentTerms().then(setPaymentTerms);
        }
        setCreditMemos(dic.creditMemoRepository.getAvailableCreditMemos());
    }, []);

    useEffect(() => {
        const token = useToken();
        const paymentMethod = token.paymentMethod;

        if (paymentMethod === PaymentMethod.TERMS && paymentTerms && cart) {
            if (paymentTerms.hasExceedLimit(cart.totalAmount)) {
                setExceedTermsLimit(true);
            }
        }

    }, [paymentTerms, cart]);

    useEffect((): void => {
        if (!isMounted) {
            return;
        }

        if (cart?.freeStockAt) {
            state?.setExpirationDate(cart.freeStockAt.toISOString());
        }

        if (cart?.creditMemo) {
            creditMemoForm.setValue('creditMemo', cart.creditMemo.id);
        }

    }, [cart]);

    useEffect(() => {
        creditMemos.then((elements: ICreditMemo[]) => {
            const selected = elements.find((element: ICreditMemo) => {
                return element.id === watchCreditMemo;
            });
            if (UseCartSummaryList.cart) {
                UseCartSummaryList.cart.creditMemo = selected;
            }
        });
    }, [watchCreditMemo]);

    useEffect(() => {
        if (UseCartSummaryList.erpUser) {
            UseCartTransport.UseTransportForm.setValue(
                TransportFormName.ALLOW_QUOTE_TRANSPORT,
                // @ts-ignore
                UseCartSummaryList.erpUser.allowQuoteTransport,
            );
        }
    }, [UseCartSummaryList.erpUser]);

    useEffect((): void => {
        updateCartTransportAfterCourierSelection();
    }, [
        watchCourier,
        UseCartTransport.watchLiftGate,
        UseCartTransport.liftGateCouriers,
        UseCartTransport.noLiftGateCouriers,
    ]);

    useEffect(() => {
        handleTransportPriceOnPickHandleChange();
    }, [
        cart,
        watchConsolidated,
        UseCartSummaryList.erpUser,
        UseCartTransport.pickHandlePrice,
    ]);

    return {
        cart,
        state,
        origin,
        pageName,
        UseCartPO,
        creditMemos,
        confirmCart,
        tokenPayload,
        watchLiftGate,
        creditMemoForm,
        watchResponsible,
        UseCartTransport,
        exceedTermsLimit,
        watchConsolidated,
        UseCartSummaryList,
        cartSummaryScreenIsBusy,
        shippingAddressSelected,
        showShippingAddressError,
        handleHideShippingAddressError,
        afterChangeShippingAddressDefault,
    };
};
