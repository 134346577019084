import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { Courier } from '../../domain/entity/Courier';
import { CourierMapper } from './api/dataMappers/CourierMapper';
import { WowCourierRepository } from '../../domain/repository/WowCourierRepository';

export class ApiWowCourierRepository implements WowCourierRepository {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async findAll(
        hasLiftGate: boolean,
        consolidated: boolean,
    ): Promise<Courier[]> {
        const response = await this.client.getWowCouriers({
            'has-liftgate': hasLiftGate,
            'is-consolidated': consolidated,
        });

        return CourierMapper.fromArrayResponse(response);
    }

}
