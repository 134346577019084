import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CANCEL, TOTAL_AMOUNT, VALIDATE_CART } from '../../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { Currency } from '../../../../shared/Currency';
import { UseCartPOResponse } from '../../hooks/useCartPO';
import Cart from '../../../../cart/domain/model/Cart';
import { useIsMounted } from '../../../../shared/hooks/useIsMounted';

interface Props {
    cart: Cart;
    onSubmit: () => Promise<void>;
    UseCartPO: UseCartPOResponse;
}

export const CartSummaryFooter: React.FC<Props> = (props: Props) => {
    const isMounted = useIsMounted();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [confirmInProgress, setConfirmInProgress] = useState(false);

    const backToCart = (): void => {
        navigate(TencerRoutes.CART);
    };

    const onSubmit = async (): Promise<void> => {
        if (!isMounted) {
            return;
        }

        setConfirmInProgress(true);
        await props.onSubmit()
            .finally(() => setConfirmInProgress(false));
    };

    return (
        <div className="cart-summary-footer">
            <div className="cart-summary-footer-price-container">
                <div className="cart-summary-footer-total-price-title">
                    {t(TOTAL_AMOUNT)}
                </div>
                <div className="cart-summary-footer-total-price-import">
                    <Currency value={props.cart.totalAmount.amount} />
                </div>
            </div>
            <div className="cart-summary-footer-buttons-container">
                <button
                    type="button"
                    onClick={backToCart}
                    className="btn btn-primary-outline"
                >
                    {t(CANCEL)}
                </button>

                <button
                    type="button"
                    onClick={onSubmit}
                    disabled={props.UseCartPO.POIsBusy || confirmInProgress}
                    className="c-action-button btn action-button order-confirmation-button"
                >
                    {
                        props.UseCartPO.POIsBusy || confirmInProgress ? (
                            <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm"
                            />
                        ) : null
                    }
                    <span className="text">
                        {t(VALIDATE_CART)}
                    </span>
                </button>
            </div>
        </div>
    );
};
