import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOWNLOAD_PROFORMA, LOADING } from 'i18n/translations/TR';
import { CActionButton } from 'app/shared/components/CActionButton/CActionButton';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { useProforma } from '../hooks/useProforma';

interface Props {
    orderId: OrderId
}

export const DownloadProformaButton: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const proforma = useProforma();

    return (
        <CActionButton
            text={t(DOWNLOAD_PROFORMA)}
            isBusy={proforma.proformaLoading}
            disabled={proforma.proformaLoading}
            onClick={(): void => proforma.download(props.orderId)}
            textIsBusy={proforma.error ? proforma.error : t(LOADING)}
        />
    );
};
