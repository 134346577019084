export enum TencerRoutes {
    HOME = '/',
    LOGIN = '/login',
    LOGIN_SIGN_IN = '/login/sign-in',
    LOGIN_SIGN_UP = '/login/sign-up',
    RESET_PASSWORD = '/resetPassword/:token',
    CATALOG_GENERAL = '/catalog',
    CATALOG = '/products',
    PRODUCT_DETAILS = '/products/:id',
    PRODUCT_BESTSELLERS = '/product-bestsellers',
    PRODUCT_OUTLET = '/product-outlet',
    COLLECTION_DETAILS = '/collections/:id',
    COLLECTIONS = '/collections',
    MARKETING = '/marketing',
    ACCOUNT = '/account',
    ORDERS = '/orders',
    ORDER_DETAIL = '/orders/:id',
    INVOICES = '/invoices',
    INVOICE_DETAIL = '/invoices/:id',
    LOADING_ORDERS = '/loading-orders',
    LOADING_ORDER_DETAIL = '/loading-orders/:id',
    CART = '/cart',
    CART_SUMMARY = '/cart-summary',
    CART_PAYMENT_CARD = '/cart-payment-card',
    CHECKOUT_OK = '/checkout/ok',
    CHECKOUT_KO = '/checkout/ko',
    CART_PAYMENT_OK = '/payment/ok',
    CART_PAYMENT_KO = '/payment/ko',
}
