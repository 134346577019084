import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CONTACT_SUPPORT } from '../../../../i18n/translations/TR';
import { CActionButton } from '../CActionButton/CActionButton';
import { useSupportEmail } from '../../hooks/useSupportEmail';

export const SupportButton = (): ReactElement => {
    const { openSupportEmail } = useSupportEmail();
    const { t } = useTranslation();

    return (
        <CActionButton
            text={t(CONTACT_SUPPORT)}
            onClick={openSupportEmail}
            className="btn-primary-outline"
        />
    );
};
