import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { Discount } from 'app/orders/model/value-objects/Discount';
import { ShippingAddressId } from '../../../orders/model/ShippingAddressId';
import { Price } from '../../../orders/model/value-objects/Price';
import { Weight } from '../../../orders/model/value-objects/Weight';
import { CartId } from '../value-objects/CartId';
import CartItem from './CartItem';
import Transport from '../../../orders/model/transport';
import { ICreditMemo } from '../../../orders/model/CreditMemo';
import { UserClientRef } from '../../../shared/domain/value-objects/UserClientRef';

export default class Cart {

    public get id(): CartId {
        return this._id;
    }

    public get orderId(): OrderId | null {
        return this._orderId;
    }

    public get deliveryAddressCode(): ShippingAddressId {
        return this._deliveryAddressCode;
    }

    public get clientInternalRef(): UserClientRef | null {
        return this._clientInternalRef;
    }

    public set clientInternalRef(value: UserClientRef | null) {
        this._clientInternalRef = value;
    }

    public get freeStockAt(): Date {
        return this._freeStockAt;
    }

    public get weight(): Weight {
        return this._weight;
    }

    public get price(): Price {
        return this._price;
    }

    public set price(value: Price) {
        this._price = value;
    }

    public get items(): CartItem[] {
        return this._items;
    }

    public get discounts(): Discount[] {
        return this._discounts;
    }

    public set creditMemo(value: ICreditMemo | undefined) {
        this._creditMemo = value;
    }

    public get creditMemo(): ICreditMemo | undefined {
        return this._creditMemo;
    }

    public constructor(
        private _id: CartId,
        private _clientInternalRef: UserClientRef | null,
        private _deliveryAddressCode: ShippingAddressId,
        private _freeStockAt: Date,
        private _items: CartItem[] = [],
        private _orderId: OrderId | null,
        private _price: Price,
        private _weight: Weight,
        private _discounts: Discount[] = [],
        private _creditMemo?: ICreditMemo,
        private _transport?: Transport,
    ) {}

    public brutePrice(): Price {
        return new Price(this._items.reduce((acc, item) => acc + item.price.amount, 0));
    }

    public get transport(): Transport | undefined {
        return this._transport;
    }

    public set transport(value: Transport | undefined) {
        this._transport = value;
    }

    public discountsAmount(): number {
        let brute = this.brutePrice().amount;
        let totalDiscounted = 0;
        this._discounts.forEach((discount) => {
            const amountDiscounted = Number((brute * (discount.value / 100)).toFixed(2));
            brute -= amountDiscounted;
            totalDiscounted += amountDiscounted;
        });

        return totalDiscounted;
    }

    public get totalAmount(): Price {
        let amount:Price = this._price;
        if (this.transport?.courier?.price) {
            amount = amount.add(this.transport.courier.price);
        }

        if (this.transport?.pickHandlePrice) {
            amount = amount.add(this.transport.pickHandlePrice);
        }

        if (this._creditMemo) {
            const creditMemoAmount = new Price(this._creditMemo.amount);
            amount = amount.subtract(creditMemoAmount);
        }

        return new Price(Math.max(0, amount.amount));
    }

    public get creditMemoValueApplied(): Price {
        if (this._creditMemo) {
            const creditAmount = this._creditMemo.amount;
            const cartAmount = this._price.amount;
            const transportAmount = this.transport?.courier?.price?.amount ?? 0;
            const pickHandleAmount = this.transport?.pickHandlePrice?.amount ?? 0;
            const totalAmountWithoutCreditMemo = cartAmount + transportAmount + pickHandleAmount;

            if (totalAmountWithoutCreditMemo > creditAmount) {
                return new Price(creditAmount);
            }
            return new Price(totalAmountWithoutCreditMemo);

        }

        return new Price(0);

    }

}
