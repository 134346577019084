import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CHECKOUT_KO_TEXT, CHECKOUT_KO_TITLE, REVIEW_ORDER } from '../../../../../../i18n/translations/TR';
import { ReactComponent as IconError } from '../../../../../../assets/img/icons/ic-error.svg';
import './checkoutKo.scss';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';
import { SupportButton } from '../../../../../shared/components/supportButton/SupportButton';
import { TencerRoutes } from '../../../../../shared/tencer-routing/TencerRoutes';

export const CheckoutKo = (): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onReset = (): void => {
        navigate(TencerRoutes.CART_SUMMARY);
    };

    return (
        <div className="component-checkout-ko payment-result payment-result-ko">
            <div className="header">
                <div className="title">
                    <h1 className="section-title">
                        <span className="icon"><IconError /></span>
                        <span className="text">{t(CHECKOUT_KO_TITLE)}</span>
                    </h1>
                </div>
            </div>
            <div className="body">
                <p>{t(CHECKOUT_KO_TEXT)}</p>
                <div className="actions">
                    <CActionButton
                        onClick={onReset}
                        text={t(REVIEW_ORDER)}
                        textIsBusy={t(REVIEW_ORDER)}
                    />
                    <SupportButton />
                </div>
            </div>
        </div>
    );
};
