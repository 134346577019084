import React, { ReactElement } from 'react';
import classNames from 'classnames';

interface ICartResultScreenProps {
    pageName: string,
    children: ReactElement
}

// TODO: this is a template not a screen and the screens should use it.
// We can include here the header and body and just pass the info by props
export const CartResultScreen = (props: ICartResultScreenProps): ReactElement => {
    return (
        <div className={classNames('screen', `screen-${props.pageName}`)}>
            <div className={classNames('container', `container-${props.pageName}`, 'screen-container', 'l-modal')}>
                {props.children}
            </div>
        </div>
    );
};
