import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { HomeScreen } from 'app/home/presentation/HomeScreen';
import { CheckoutKo } from './cartSummary/application/components/checkout/checkoutKo/CheckoutKo';
import { TencerRoutes } from './shared/tencer-routing/TencerRoutes';
import { TencerAuthServiceInstance } from './shared/tencer-routing/tencer-auth-route/TencerAuthService';
import { ScrollTop } from './shared/ScrollTop';
import { Header } from './shared/Header';
import { Footer } from './shared/Footer';
import { SignInScreen } from './login/sign-in/SignInScreen';
import { AccountScreen } from './account/application/screen/account-screen';
import { SignUpScreen } from './login/sign-up/SignUpScreen';
import { CollectionDetails } from './collection-details/CollectionDetailsScreen';
import { ProductDetailsScreen } from './product-details/ProductDetailsScreen';
import { PasswordRecoveryScreen } from './password-recovery/PasswordRecoveryScreen';
import { CartScreen } from './cart/CartScreen';
import { OrderDetailScreen } from './orders/application/detail/order-detail-screen';
import { OrdersScreen } from './orders/application/list/OrdersScreen';
import { LoadingOrderListScreen } from './loading-order/application/list/screen/loading-order-list-screen';
import { CartSummaryScreen } from './cartSummary/application/CartSummaryScreen';
import { LoadingOrderDetailScreen } from './loading-order/application/detail/screen/loading-order-detail-screen';
import { useOrigin } from './shared/hooks/useOrigin';
import { Origin } from './shared/domain/value-objects/Origin';
import { EnvEnum } from './shared/domain/value-objects/EnvEnum';
import { CartPaymentCardScreen } from './cart/screens/CartPaymentCard/CartPaymentCardScreen';
import { CartResultScreen } from './cartSummary/application/screens/cartResult/CartResultScreen';
import { PaymentKo } from './cartSummary/application/components/payment/paymentKO/PaymentKo';
import { PaymentOk } from './cartSummary/application/components/payment/paymentOk/PaymentOk';
import { InvoicesScreen } from './invoices/application/screens/list/InvoicesScreen';
import { InvoiceDetailScreen } from './invoices/application/screens/detail/InvoiceDetailScreen';
import { ProductBestsellersScreen } from './product-catalog-collections/bestsellers/ProductBestsellersScreen';
import { ProductOutletScreen } from './product-catalog-collections/outlet/ProductOutletScreen';
import { MarketingCollectionView } from './product-catalog-collections/components/MarketingCollection/MarketingCollection';
import { ProductCatalogScreen } from './product-catalog-collections/Catalog/ProductCatalogScreen';
import { ProductCollectionsScreen } from './product-catalog-collections/Collections/ProductCollectionsScreen';
import { CheckoutOk } from './cartSummary/application/components/checkout/checkoutOk/CheckoutOk';

export const AppRouting: React.FC = () => {
    const origin = useOrigin();

    const [search, setSearch] = React.useState('');

    const routes = (
        <Routes>
            <Route
                path={TencerRoutes.LOGIN_SIGN_IN}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <SignInScreen />
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.LOGIN_SIGN_UP}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <SignUpScreen />
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.ACCOUNT}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <AccountScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.HOME}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <HomeScreen title="home" url="/" />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.COLLECTION_DETAILS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <CollectionDetails />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.PRODUCT_DETAILS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <ProductDetailsScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.RESET_PASSWORD}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <PasswordRecoveryScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.CART}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <CartScreen />
                        </main>
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.ORDER_DETAIL}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <OrderDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.ORDERS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <OrdersScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.INVOICES}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <InvoicesScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.INVOICE_DETAIL}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <InvoiceDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            {
                process.env.REACT_APP_ENV !== EnvEnum.PROD ? (
                    <Route
                        path={TencerRoutes.LOADING_ORDERS}
                        element={TencerAuthServiceInstance.isAuthenticated()
                        && origin === Origin.EUROPE
                            ? (
                                <div className="co-app">
                                    <main>
                                        <Header search={search} setSearch={setSearch} />
                                        <LoadingOrderListScreen />
                                    </main>
                                    <Footer />
                                </div>
                            ) : (
                                <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                            )}
                    />
                ) : null
            }
            {
                process.env.REACT_APP_ENV !== EnvEnum.PROD ? (
                    <Route
                        path={TencerRoutes.LOADING_ORDER_DETAIL}
                        element={TencerAuthServiceInstance.isAuthenticated() && origin === Origin.EUROPE
                            ? (
                                <div className="co-app">
                                    <main>
                                        <Header search={search} setSearch={setSearch} />
                                        <LoadingOrderDetailScreen />
                                    </main>
                                    <Footer />
                                </div>
                            ) : (
                                <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                            )}
                    />
                ) : null
            }
            <Route
                path={TencerRoutes.CART_SUMMARY}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <CartSummaryScreen />
                        </main>
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <CartScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART_PAYMENT_CARD}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <CartPaymentCardScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CHECKOUT_KO}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <CartResultScreen
                        pageName="checkout-result"
                    >
                        <CheckoutKo />
                    </CartResultScreen>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CHECKOUT_OK}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <CartResultScreen
                        pageName="checkout-result"
                    >
                        <CheckoutOk />
                    </CartResultScreen>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART_PAYMENT_KO}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <CartResultScreen
                        pageName="payment-result"
                    >
                        <PaymentKo />
                    </CartResultScreen>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART_PAYMENT_OK}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <CartResultScreen
                        pageName="payment-result"
                    >
                        <PaymentOk
                            koURL={TencerRoutes.CART_PAYMENT_KO}
                        />
                    </CartResultScreen>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.COLLECTIONS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <ProductCollectionsScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CATALOG}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <ProductCatalogScreen
                                search={search}
                                setSearch={setSearch}
                            />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />

            <Route
                path={TencerRoutes.PRODUCT_BESTSELLERS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <ProductBestsellersScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.PRODUCT_OUTLET}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header search={search} setSearch={setSearch} />
                            <ProductOutletScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />

            <Route
                path={TencerRoutes.MARKETING}
                element={TencerAuthServiceInstance.isAuthenticated() &&
                origin === Origin.USA ?
                    (
                        <div className="co-app">
                            <main>
                                <Header search={search} setSearch={setSearch} />
                                <MarketingCollectionView />
                            </main>
                            <Footer />
                        </div>
                    ) : (
                        <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                    )}
            />
            <Route path="*" element={<Navigate to={TencerRoutes.HOME} />} />
        </Routes>
    );

    return (
        <React.Suspense fallback={null}>
            <BrowserRouter>
                <ScrollTop />
                {routes}
            </BrowserRouter>
        </React.Suspense>
    );
};
