import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    COMPLETE_ALL_FIELDS,
    CORREO,
    LOGIN,
    LOGIN_SENTENCE,
    PASSWORD,
    PASSWORD_FORGOTTEN,
    REQUEST_ACCESS,
} from 'i18n/translations/TR';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ReactComponent as Visibility } from 'assets/img/icons/ic-visibility.svg';
import { ReactComponent as VisibilityOff } from 'assets/img/icons/ic-visibility-off.svg';
import { CurrentUserContext } from '../../../../shared/context/CurrentUserContext';

import { ModalPassword } from './ModalPassword';
import { ErrorMessage } from './ErrorMessage';
import { ApplyAccessModal } from '../../../sign-up/components/applyAccessModal/ApplyAccessModal';
import { InvalidAccessModal } from './InvalidAccessModal';

interface FormInputs {
    email: string,
    password: string,
}

export const LoginForm = (): React.ReactElement => {

    const { t } = useTranslation();
    const history = useNavigate();

    // Access to state of CurentUserContext
    const state = React.useContext(CurrentUserContext);
    // Handling data from form
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormInputs>();
    const [hasError, setHasError] = useState(false);
    const [applyAccessOpened, setApplyAccessOpened] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // Handling modal
    const [show, setShow] = useState(false);
    const handleClose = (): void => setShow(false);
    const handleShow = (): void => setShow(true);

    const handleApplyAccess = (): void => {
        setApplyAccessOpened(true);
    };
    const applyAccessClosed = (): void => {
        setApplyAccessOpened(false);
    };

    // Reload on any change of userInfo
    useEffect(() => {
        if (state?.errorMessage) {
            setHasError(true);
        }

    }, [state?.currentUser, state?.errorMessage, history]);

    const submitHandler: SubmitHandler<FormInputs> = (data: FormInputs) => {
        if (state?.handleLogin) {
            // Dispatch to action
            state?.handleLogin(data.email, data.password);
        }
    };

    const refreshForm = (): void => {
        state?.refreshForm();
        setHasError(false);
    };

    const handlePasswordVisibility = (): void => {
        setShowPassword(!showPassword);
    };

    const watchPassword = watch('password');

    return (

        <div className="login-page-form">
            <form onSubmit={handleSubmit(submitHandler)}>
                <p className="login-form-title">
                    {t(LOGIN)}
                </p>
                <p className="login-form-subtitle">
                    {t(LOGIN_SENTENCE)}
                </p>
                <div className="form-floating mb-3">
                    <input
                        onClick={refreshForm}
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="email"
                        {...register(
                            'email',
                            { required: true },
                        )}
                    />
                    <label htmlFor="floatingInput">{t(CORREO)}</label>
                </div>
                <div className="form-floating login-form-field password-field">
                    <input
                        onClick={refreshForm}
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        id="floatingPassword"
                        placeholder="password"
                        {...register(
                            'password',
                            { required: true },
                        )}
                    />
                    <label htmlFor="floatingPassword">{t(PASSWORD)}</label>
                    {
                        watchPassword ? (
                            <button
                                type="button"
                                className="visibility-button"
                                onClick={handlePasswordVisibility}
                            >
                                {showPassword ?
                                    <VisibilityOff /> :
                                    <Visibility />}
                            </button>
                        ) : null
                    }
                </div>

                { errors.password || errors.email ? <ErrorMessage message={t(COMPLETE_ALL_FIELDS)} /> : null }
                { hasError ? <ErrorMessage message={state?.errorMessage} /> : null }

                <ApplyAccessModal
                    show={applyAccessOpened}
                    onHide={applyAccessClosed}
                />

                <button
                    type="submit"
                    className="btn btn-primary centeredButton mt-5"
                    onClick={(): void => {
                        state?.setError(undefined);
                    }}
                    disabled={state?.isSubmittingLogin}
                >
                    {state?.isSubmittingLogin ? (
                        <span
                            className="spinner-border spinner-border-sm button-loading"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : null}
                    {t(LOGIN)}
                </button>

                <button
                    className="centeredButton btn-link bg-transparent border-0 mt-4"
                    type="button"
                    onClick={handleShow}
                >
                    {t(PASSWORD_FORGOTTEN)}
                </button>

                <button
                    className="centeredButton btn-link bg-transparent border-0 mt-4"
                    type="button"
                    onClick={handleApplyAccess}
                >
                    {t(REQUEST_ACCESS)}
                </button>

            </form>

            <ModalPassword
                show={show}
                onHide={handleClose}
            />

            <InvalidAccessModal show={state!.showAccessInvalidModal} onClose={state!.closeAccessInvalidModal} />

        </div>
    );
};
