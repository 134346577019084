import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseCartSummaryResponse } from '../../../../hooks/useCartSummary';
import { PO_LABEL } from '../../../../../../../i18n/translations/TR';

interface IOrderPOProps {
    UseCartSummary: UseCartSummaryResponse
}

export const OrderPOField = ({
    UseCartSummary,
}: IOrderPOProps): React.ReactElement => {
    const { t } = useTranslation();

    const register = UseCartSummary.UseCartPO.clientRefInput;
    const error = UseCartSummary.UseCartPO.cartPOForm.formState.errors.clientRef;

    return (
        <div className="po-container">
            <label
                htmlFor="clientRef"
                className="cart-content-subtitle"
            >
                <span>{t(PO_LABEL)}</span>
            </label>
            <input
                type="text"
                {...register}
                id="clientRef"
                maxLength={20}
                className="po-input"
            />
            {
                error ? (
                    <div className="error-input-form">
                        {error.message}
                    </div>
                ) : null
            }
        </div>
    );
};
