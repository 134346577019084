import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    OWN_LOGISTIC_LABEL,
    SMALL_PARCEL_LABEL,
    WILL_CALL_LABEL,
    WOW_LABEL,
} from '../../../../../../../../i18n/translations/TR';
import { ETransportType } from '../../../../../../../orders/model/value-objects/transport-type';
import { EnvEnum } from '../../../../../../../shared/domain/value-objects/EnvEnum';

interface ResponsibleFieldProps {
    disabled: boolean,
    allowQuoteTransport: boolean,
    responsibleRegister: UseFormRegisterReturn,
}

export const ResponsibleField = (props: ResponsibleFieldProps): React.ReactElement => {
    const { t } = useTranslation();

    const renderResponsibleFieldItem = (
        value: ETransportType,
        label: string,
        id: string,
    ): React.ReactElement => {
        return (
            <div className="form-check transport-form-check">
                <input
                    id={id}
                    type="radio"
                    value={value}
                    disabled={props.disabled}
                    className="form-check-input"
                    {...props.responsibleRegister}
                />
                <label
                    htmlFor={id}
                    className="form-check-label"
                >
                    <strong>{label}</strong>
                </label>
            </div>
        );
    };

    return (
        <div className="transport-form-container-item">
            {renderResponsibleFieldItem(
                ETransportType.WILL_CALL,
                t(WILL_CALL_LABEL),
                'willCallTransport',
            )}
            {renderResponsibleFieldItem(
                ETransportType.OWN_LOGISTIC,
                t(OWN_LOGISTIC_LABEL),
                'ownLogisticTransport',
            )}
            {renderResponsibleFieldItem(
                ETransportType.SMALL_PARCEL,
                t(SMALL_PARCEL_LABEL),
                'smallParcelTransport',
            )}
            {!props.allowQuoteTransport &&
            process.env.REACT_APP_ENV !== EnvEnum.PROD
                ? renderResponsibleFieldItem(
                    ETransportType.WOW,
                    t(WOW_LABEL),
                    'wowTransport',
                ) : null}
        </div>
    );
};
