import React from 'react';
import { Summary } from './summary/Summary';
import { UseCartSummaryResponse } from '../../hooks/useCartSummary';
import { CartSummaryList } from './list/CartSummaryList';
import { ShippingInstructions } from './shippingInstructions/ShippingInstructions';

interface ICartSummaryBodyProps {
    UseCartSummary: UseCartSummaryResponse;
}

export const CartSummaryBody = (
    {
        UseCartSummary,
    }: ICartSummaryBodyProps,
): React.ReactElement => {

    return (
        <div className="cart-summary-content">
            <div className="col-md-7">
                <CartSummaryList
                    UseCartSummary={UseCartSummary}
                />
            </div>
            <div className="col-md-4">
                <ShippingInstructions
                    UseCartSummary={UseCartSummary}
                />
                {UseCartSummary.cart ? (
                    <Summary
                        cart={UseCartSummary.cart}
                        timer={UseCartSummary.state?.timer}
                        creditMemos={UseCartSummary.creditMemos}
                        creditMemoForm={UseCartSummary.creditMemoForm}
                        cartWeight={UseCartSummary.cart!.weight.weight}
                        watchConsolidated={UseCartSummary.watchConsolidated}
                        watchTransportResponsible={UseCartSummary.watchResponsible}
                        pickHandlePrice={UseCartSummary.UseCartTransport.pickHandlePrice}
                        hasPickHandle={UseCartSummary.UseCartSummaryList.erpUser?.pickHandle ?? false}
                    />
                ) : null}

            </div>
        </div>
    );
};
