import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseCartSummaryResponse } from '../../../hooks/useCartSummary';
import { SHIPPING_INSTRUCTIONS } from '../../../../../../i18n/translations/TR';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { OrderTransportForm } from './orderTransportForm/OrderTransportForm';
import { OrderPOField } from './orderPOField/OrderPOField';
import { OrderShippingAddress } from './orderShippingAddress/OrderShippingAddress';
import { ETransportType } from '../../../../../orders/model/value-objects/transport-type';

interface IShippingInstructionsProps {
    UseCartSummary: UseCartSummaryResponse;
}

export const ShippingInstructions = ({
    UseCartSummary,
}: IShippingInstructionsProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="shipping-instructions-container">
            <h5 className="card-wow-title">{t(SHIPPING_INSTRUCTIONS)}</h5>
            <div className="card-box">
                <OrderPOField
                    UseCartSummary={UseCartSummary}
                />

                {
                    UseCartSummary.watchResponsible !== ETransportType.WILL_CALL ||
                UseCartSummary.origin === Origin.EUROPE ?
                        (
                            <OrderShippingAddress
                                UseCartSummary={UseCartSummary}
                            />
                        ) : null
                }

                {UseCartSummary.origin === Origin.USA ? (
                    <OrderTransportForm
                        UseTransportForm={UseCartSummary.UseCartTransport.UseTransportForm}
                        cart={UseCartSummary.cart ?? undefined}
                        UseCartSummary={UseCartSummary}
                    />
                ) : null}
            </div>
        </div>
    );
};
