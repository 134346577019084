import { CourierMapper } from 'app/cartSummary/infrastructure/repository/api/dataMappers/CourierMapper';
import {
    ETransportTypeDTO,
    OrderShipmentResponse,
} from '../../../../../shared/infrastructure/tencer-api/dto/responses/order-shipment-response';
import Transport from '../../../../model/transport';
import { ETransportType, TransportType } from '../../../../model/value-objects/transport-type';
import { CartShippingResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/CartShippingResponse';
import { Price } from '../../../../model/value-objects/Price';
import { LoadId } from '../../../../model/value-objects/LoadId';
import { CourierServiceMapper } from '../../../../../cartSummary/infrastructure/repository/api/dataMappers/CourierServiceMapper';

export class TransportMapper {

    private static transportTypeToDomain(type: ETransportTypeDTO): ETransportType {
        switch (type) {
            case ETransportTypeDTO.WOW:
                return ETransportType.WOW;
            case ETransportTypeDTO.OWN_LOGISTIC:
                return ETransportType.OWN_LOGISTIC;
            case ETransportTypeDTO.WILL_CALL:
                return ETransportType.WILL_CALL;
            case ETransportTypeDTO.SMALL_PARCEL:
            default:
                return ETransportType.SMALL_PARCEL;
        }
    }
    static fromResponse(response: OrderShipmentResponse): Transport {
        return new Transport(
            new TransportType(this.transportTypeToDomain(response.type.value)),
            response.consolidated ? response.consolidated : false,
            response.description,
            response.account_number,
            response.courier ? CourierMapper.fromResponse(response.courier) : undefined,
            response.courier_service ? CourierServiceMapper.fromResponse(response.courier_service) : undefined,
            response.lift_gate,
            response.loadId ? new LoadId(response.loadId) : undefined,
            undefined,
        );
    }

    static fromCartResponse(response?: CartShippingResponse): Transport | undefined {
        if (!response) {
            return undefined;
        }

        return new Transport(
            new TransportType(this.transportTypeToDomain(response.shipping_type.value)),
            response.consolidated ? response.consolidated : false,
            response.description || '',
            response.account_number,
            response.courier ? CourierMapper.fromResponse(response.courier) : undefined,
            response.courier_service ? CourierServiceMapper.fromResponse(response.courier_service) : undefined,
            response.lift_gate || undefined,
            undefined,
            response.pick_handle_price ? new Price(response.pick_handle_price) : undefined,
        );

    }

}
